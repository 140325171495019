export default {
  experience: {
    type: {
      handsOn: 'Tip Hands-On',
      mentorship: 'Tip Mentorat',
      shadowing: 'Tip Shadowing',
    },
    status: {
      draft: 'Ciornă',
      completed: 'Finalizată',
      expired: 'Expirată',
      canceled: 'Anulată',
      shadowBan: 'În așteptarea aprobării',
    },
    title: 'Descriere',
    editForm: {
      reupload: 'Reîncarcă',
      name: 'Nume*',
      startDate: 'Data de începere*',
      endDate: 'Data de finalizare*',
      startTime: 'Ora de începere*',
      endTime: 'Ora de finalizare*',
      experienceType: 'Tipul experienței*',
      domain: 'Domeniu*',
      participantsNumber: 'Numărul de participanți (maxim)*',
      deadlineDate: 'Data limită*',
      deadlineTime: 'Ora limită*',
      presentationVideo: 'Video de prezentare*',
      presentationPhoto: 'Fotografie de prezentare*',
      location: 'Locație*',
      benefits: 'Beneficii*',
      requirements: 'Cerințe*',
      exminderDisclaimer: 'Cerințe Exminder*',
      additionalCosts: 'Costuri suplimentare',
      currency: 'Monedă*',
      price: 'Preț*',
      discountsPercentage: 'Procent discount',
      referralCode: 'Cod de referință',
      contact: 'Contact',
      description: 'Descrierea experienței',
    },
    editDisclaimer: {
      title: 'Editează cerințe Exminder',
      subtitle:
        'Oferă toate informațiile importante pentru Explorer pentru a înțelege fiecare aspect al Experienței',
      text: 'Descrierea cerințelor',
    },
    editDescription: {
      title: 'Editează descrierea experienței',
      subtitle:
        'Oferă toate informațiile importante pentru Explorer pentru a înțelege fiecare aspect al acestei experiențe particulare',
      text: 'Conținutul descrierii',
    },
    editCosts: {
      title: 'Editează costurile suplimentare',
      subtitle:
        'Oferă toate informațiile importante privind costurile suplimentare potențiale pentru Explorer pentru a înțelege fiecare aspect al Experienței',
      text: 'Costuri suplimentare',
    },
    ongoing: {
      mentionSomething: 'Menționează ceva',
      takingPlace: 'Această Experiență are loc.\nBucură-te!',
    },
    errors: {
      purchaseDeadlineDate:
        'Data limită de cumpărare trebuie să fie înainte de data de începere',
    },
    filters: {
      accounts: 'Conturi',
      experiences: 'Experiențe',
      auctions: 'Licitații',
    },
    explorer: {
      myExperiences: {
        welcome: 'Bun venit, ',
        youHave: 'Ai',
        experiencePurchased: 'Experiență cumpărată',
        experiencesPurchased: 'Experiențe cumpărate',
        experienceCompleted: 'Experiență finalizată',
        experiencesCompleted: 'Experiențe finalizate',
        experienceRequested: 'Experiență solicitată',
        experiencesRequested: 'Experiențe solicitate',
        purchasedBy: 'Cumpărat de',
        unpurchasedExperience: 'Experiență necumpărată',
        noActiveExplorersBought:
          'Niciun Explorer activ nu a cumpărat acest pachet',
        thisIsThePlace:
          'Acesta este locul unde poți stoca toate Experiențele tale viitoare!',
        checkIfTheExminders:
          'Verifică dacă Exminderii au acceptat solicitările tale pentru Experiențele dorite. Odată acceptate, va trebui să continui și să achiziționezi Experiența.',
        lookingForFreshExperiences: 'Cauți Experiențe noi din care să înveți?',
        upcoming: 'În viitor',
        auction: 'Licitație',
        past: 'Completate',
        requested: 'Solicitate',
        discoverExperiences: 'Descoperă Experiențe',
        noPurchasedExperiences: 'Se pare că nu ai nicio Experiență cumpărată',
        noRequestedExperiences: 'Se pare că nu ai nicio Experiență solicitată',
        noCompletedExperiences: 'Se pare că nu ai nicio Experiență completată',
        findYourFavoriteOnes:
          'Găsește mai multe Experiențe în Universul Exminds!',
        exploreMore: 'Explorează',
      },
    },
    permanent: {
      name: 'Permanent',
      requestApproved: 'Cerere aprobată!',
      youHaveLeftToBuy: 'Ai 3 zile să cumperi această Experiență',
      exminderIsOff: 'Exminderul este liber!',
      isNotAvailable:
        'nu este disponibil(ă) în acest moment pentru Experiența care te interesează',
    },
    refund: {
      request: 'Solicită Rambursare',
    },
    request: {
      approved: 'Aprobată',
      declined: 'Refuzată',
    },
    inProgress: 'În desfășurare',
    upcoming: 'În viitor',
    requested: 'Solicitată',
    pinCodeValidatedAt: 'Codul PIN validat la',
    showProfile: 'Arată Profilul',
    checkNotifications: 'Verifică-ți notificările!',
    stayUpdated:
      'Rămâi la curent cu toate discuțiile cu Explorerii interesați de această Experiență',
    experienceHasNoBuyers: 'Această Experiență nu are cumpărători',
    promoteExperience:
      'Promovează-ți Experiența pentru a ajuta Explorerii să descopere abilitățile tale',
    typeCard: {
      greeting: 'Bună',
      title: {
        handsOn: 'Aceasta este o Experiență tip Hands-On',
        mentorship: 'Aceasta este o Experiență tip Mentorat',
        shadowing: 'Aceasta este o Experiență tip Shadowing',
      },
      description: {
        handsOn:
          'Poți participa activ la sarcini alături de Exminder, dobândind abilități practice prin implicare directă și aplicare în situații reale.',
        mentorship:
          'Poți beneficia de îndrumare și sfaturi personalizate care te ajută să obții progres prin perspectivele unui expert.',
        shadowing:
          'Poți învăța de la Exminder în timpul acțiunii, obținând perspective valoroase prin observare în timp real.',
      },
    },
    progressTracker: {
      title: 'Cum funcționează',
      subtitle:
        'Curios cum funcționează fluxul de achiziție Exminds? Iată o descriere a pașilor implicați:',
      step: 'PASUL',
      interested: {
        name: 'Sunt interesat',
        description:
          'Un Explorer tocmai a descoperit o Experiență incredibilă și este entuziasmat să participe la ea. Dacă este o Experiență Permanentă, va trebui să verifici disponibilitatea Exminderului și să-ți exprimi interesul mai întâi.\n\n',
        descriptionBold:
          'Dar fii atent — asta nu înseamnă că ai asigurat Experiența încă!',
      },
      checkAvailabilityAndBuy: {
        name: 'Verifică disponibilitatea & Cumpără',
        description:
          'O întâlnire față în față necesită participarea ambelor persoane.\n\n' +
          'Te rugăm să înțelegi că Exminderul trebuie să revizuiască profilul Explorerului și istoricul său profesional pentru a-și da consimțământul înainte de a se întâlni în persoană.\n\n',
        descriptionBold:
          'Exminderul are drepturi egale de a alege cu cine va împărtăși Experiența!',
      },
      getInContact: {
        name: 'Luați legătura',
        descriptionOne:
          'Este momentul să intrați în contact unul cu celălalt.\n\n',
        descriptionTwo:
          'Acest lucru este deosebit de important pentru Experiențele Permanente, ' +
          'care nu au o dată și o oră stabilite, ' +
          'făcând acest moment ideal pentru a discuta și aranja aceste detalii.\n\n',
        descriptionBoldOne:
          'Dacă Explorerul are întrebări suplimentare, poate lua legătura cu ușurință cu Exminderul. ',
        descriptionBoldTwo:
          'Ai la dispoziție 15 zile după achiziție pentru a aranja întâlnirea.',
      },
      meetInPersonAndRequestPin: {
        name: 'Întâlniți-vă personal & Validați codul PIN',
        description:
          'Acest pas inițiază oficial Experiența și aprobă transferul banilor către Exminder. ' +
          'Dacă acest pas este omis, plata nu va fi procesată.\n\n' +
          'Îți recomandăm să nu împărtășești codul PIN înainte de întâlnirea față în față.',
        descriptionBold:
          'Pentru siguranță suplimentară, fiecare Experiență are un cod PIN unic care trebuie schimbat atunci când Exminderul și Explorerul se întâlnesc în persoană.\n\n',
      },
      startExperience: {
        name: 'Începe Experiența',
        description:
          'Adevărata călătorie începe acum. Îmbrățișează fiecare secundă și primește realitatea în viața ta.',
      },
      permanentExperience: {
        stepOne: 'Sunt\ninteresat',
        stepTwo: 'Verifică disponibilitatea\n& Cumpără',
        stepThree: 'Luați\nlegătura',
        stepFour: 'Întâlniți-vă personal\n& Validați codul PIN',
        stepFive: 'Începe\nExperiența',
      },
      oneTimeExperience: {
        stepOne: 'Cumpără\nExperiența',
        stepTwo: 'Luați\nlegătura',
        stepThree: 'Întâlniți-vă personal\n& Validați codul PIN',
        stepFour: 'Începe\nExperiența',
      },
    },
  },
};
