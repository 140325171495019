export default {
  aboutTheApp: {
    aboutTheAppIntro: {
      title: 'Bine ai venit în noua dimensiune a petrecerii timpului!',
      subtitle:
        'Conectează-te cu persoanele care îți împărtășesc interesele și evoluează prin partajarea unor Experiențe incredibile cu acestea.',
    },
    aboutTheAppFirst: {
      title:
        'Aplicația Experiențelor prin care îți valorifici timpul în viața reală!',
      subtitle:
        'Transformă-ți experiența personală și profesională în lecții valoroase pentru ceilalți, sau învață alături de cei pe care îi admiri.' +
        '\n\n' +
        'Conectează-te online. Experimentează offline.',
    },
    aboutTheAppSecond: {
      title: 'Devino Exminder!',
      subtitle:
        'Împărtășește-ți cunoștințele cu ceilalți!' +
        '\n\n' +
        'Creează experiențe care transformă abilitățile tale în oportunități valoroase de evoluție pentru ceilalți.',
    },
    aboutTheAppThird: {
      title: 'Devino Explorer!',
      subtitle:
        'Întâlnește oamenii pe care i-ai admirat dintotdeauna, trăiește sesiuni pline de evoluție și remodelează-ți propria realitate.',
    },
    aboutTheAppFourth: {
      title: 'Upgradează-ți Experiența!',
      subtitle:
        'De la un Explorer începător la un Exminder maestru, te poți upgrada ori de câte ori ești gata să împărtășești cunoștințele tale cu lumea.' +
        '\n\n' +
        'Exminds este totul despre evoluție. Momentul să crești este acum.',
    },
    aboutTheAppFifth: {
      title: 'Cum funcționează?',
      subtitle:
        'Exminderul creează Experiența bazată pe cunoștințele sale practice, Explorerul o alege și... este timpul pentru o sesiune transformatoare, 1:1, în persoană!',
    },
    aboutTheAppSixth: {
      title: 'Restul este...Realitate!',
      subtitle:
        'Adu practica și învățarea activă în viața ta de zi cu zi și bucură-te de călătoria în Universul Exminds!',
    },
  },
};
