import ErrorBoundaryCustom from 'components/dumb/layouts/ErrorBoundaryCustom';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PATH from 'routes/paths';
import { useTranslation } from 'react-i18next';
import useInAppNavigate from '../../../hooks/useInAppNavigate';
import { useNetworkStatus } from '../../../hooks/network/useNetworkStatus';

interface ErrorBoundarySmartProps {
  children?: ReactNode;
}

export default function ErrorBoundarySmart({
  children,
}: ErrorBoundarySmartProps) {
  const { t } = useTranslation();
  const { navigate } = useInAppNavigate();
  const isOnline = useNetworkStatus();

  const reloadWindow = () => {
    window.location.reload();
  };

  if (!isOnline) {
    return (
      <ErrorBoundaryCustom
        title={t('networkError.title')}
        subtitle={t('networkError.subtitle')}
        action={t('networkError.action')}
        onResetErrorBoundary={reloadWindow}
      />
    );
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryCustom
          onResetErrorBoundary={() => navigate(PATH.INDEX)}
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
}
