export default {
  addExperience: {
    btnSettings: 'Settings',
    welcomeMessage: 'Welcome, ',
    youHave: 'You have',
    experience: 'active Experience',
    experiences: 'active Experiences',
    filterAll: 'All',
    filterActive: 'Active',
    filterDraft: 'Draft',
    filterCompleted: 'Completed',
    filterCanceled: 'Canceled',
    filterExpired: 'Expired',
    filterUpcoming: 'Upcoming',
    filterInProgress: 'In Progress',
    extraRewards: 'Create and activate more Experiences for more rewards!',
    previewProfileBtn: 'Preview Profile',
    chooseCategoryDropDown: 'Experiences Type',
    categoryAllExperiences: 'All Experiences',
    categoryHandsOnExperience: 'Hands-On Experiences',
    categoryMentorshipExperience: 'Mentorship Experiences',
    categoryShadowingExperience: 'Shadowing Experiences',
    createExperienceBtn: 'Create Experience',
  },
};
