import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux';
import { isAuthenticatedSelector } from '../../redux/slices/selectors';
import { queryKeys } from '../../api/constants/queryKeys';
import useGetExperiences from '../../api/hooks/experience/useGetExperiences';
import { MIND_FEED_REQUEST_LIMIT } from '../../api/constants';

export interface PreloadedMindFeedVideo {
  id: string;
  source: string;
}

function usePreloadMindFeed(limit = MIND_FEED_REQUEST_LIMIT) {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const [videos, setVideos] = useState<PreloadedMindFeedVideo[]>([]);

  const { data } = useGetExperiences(
    queryKeys.mindFeedExperiences,
    { limit },
    isAuthenticated
  );

  useEffect(() => {
    if (isAuthenticated && data) {
      const videoSources = data.pages.flatMap((page) => ({
        id: page.id,
        source: page.presentationVideo,
      }));
      setVideos(videoSources);
    }
  }, [isAuthenticated, data]);

  return { videos };
}

export default usePreloadMindFeed;
