import { MouseEvent, ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExperienceCardType,
  ExperienceStatus,
  RequestStatus,
} from 'uiModels/enums';
import { isSameMonth, isSameYear } from 'date-fns';
import formatNumberByLocale from 'helpers/functions/formatNumberByLocale';
import theme from '../../../../styles/theme/classic';
import * as SC from './styles';
import formatDomainHobbyName from '../../../../helpers/functions/formatDomainHobbyName';
import mediaPlaceholder from '../../../../assets/images/placeholders/MediaPlaceholderLogoSmall.webp';
import LocationPinIcon from '../../../../assets/icons/profileIcons/LocationPinIcon';
import CashIcon from '../../../../assets/icons/CashIcon';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import avatarPlaceholder from '../../../../assets/images/placeholders/ProfilePicEmpty.webp';
import DateUtils from '../../../../helpers/classes/DateUtils';
import LifestyleIcon from '../../../../assets/icons/domains/LifestyleIcon';
import { Exminder, Explorer } from '../../../../uiModels/interfaces';
import HandsOnIcon from '../../../../assets/icons/experience/details/HandsOnIcon';
import { ExperienceType, SvgPath } from '../../../../helpers/constants/enums';
import BusinessIcon from '../../../../assets/icons/domains/BusinessIcon';
import ExpertsIcon from '../../../../assets/icons/domains/ExpertsIcon';
import TechnologyIcon from '../../../../assets/icons/domains/TechnologyIcon';
import CreativityIcon from '../../../../assets/icons/domains/CreativityIcon';
import TravelingIcon from '../../../../assets/icons/domains/TravelingIcon';
import ParentingIcon from '../../../../assets/icons/domains/ParentingIcon';
import HealthcareIcon from '../../../../assets/icons/domains/HealthcareIcon';
import MindfulnessIcon from '../../../../assets/icons/domains/MindfulnessIcon';
import SmallTalkIcon from '../../../../assets/icons/domains/SmallTalkIcon';
import ExtrasIcon from '../../../../assets/icons/domains/ExtrasIcon';
import { Domain } from '../../../../uiModels/types';
import AdventuresIcon from '../../../../assets/icons/domains/AdventuresIcon';
import ExploreIcon from '../../../../assets/icons/buttonIcons/ExploreIcon';
import ExperienceOverlay from './ExperienceOverlay';
import MentorshipIcon from '../../../../assets/icons/experience/details/MentorshipIcon';
import ShadowingIcon from '../../../../assets/icons/experience/details/ShadowingIcon';
import { renderExperienceTypeLabel } from '../../../../helpers/functions/experienceTypes';

export enum ActionButton {
  EXPLORE = 'explore',
  SETTINGS = 'settings',
  REVIEW = 'review',
}

export interface LatestWorkExperience {
  companyName: string;
  companyRole: string;
}

export interface ExperienceCardProps {
  id: string;
  title: string;
  presentationPhoto: string;
  exminder?: Exminder;
  explorer?: Explorer;
  domain: string;
  startDate: Date;
  endDate: Date;
  price: number;
  discount?: number | null;
  currency: string;
  location: string;
  typeOfExperience: string;
  cardType?: ExperienceCardType;
  showExplorer?: boolean;
  showUser?: boolean;
  actionButton?:
    | ActionButton.EXPLORE
    | ActionButton.SETTINGS
    | ActionButton.REVIEW;
  status?: string;
  isShadowBanned?: boolean;
  href?: string;
  onSettingsClick?: CallbackFunction;
  onExploreClick?: CallbackFunction;
  onShowExminderProfileClick?: (id: string) => void;
  onShowExplorerProfileClick?: (id: string) => void;
  onLeaveAReviewClick?: (id: string) => void;
}

export default function ExperienceCard({
  id,
  title,
  presentationPhoto,
  exminder,
  explorer,
  domain,
  status,
  startDate,
  endDate,
  price,
  discount,
  currency,
  location,
  typeOfExperience,
  cardType = ExperienceCardType.Regular,
  showExplorer = false,
  showUser = true,
  actionButton,
  isShadowBanned = false,
  href,
  onExploreClick,
  onLeaveAReviewClick,
  onSettingsClick,
  onShowExminderProfileClick,
  onShowExplorerProfileClick,
}: ExperienceCardProps) {
  const { t } = useTranslation();

  const effectiveStatus = isShadowBanned
    ? ExperienceStatus.ShadowBanned
    : status;

  const finalPrice: number = discount
    ? price - ((discount || 0) * price) / 100
    : price;

  const hasActionButton =
    actionButton === ActionButton.EXPLORE ||
    actionButton === ActionButton.SETTINGS ||
    actionButton === ActionButton.REVIEW;

  const hasLink = href && href.length > 0;

  const formattedDateRange = (): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (DateUtils.isSameDay(start, end)) {
      return DateUtils.formatDate(start);
    }
    if (isSameYear(start, end)) {
      if (isSameMonth(start, end)) {
        return `${DateUtils.formatDate(start, 'dd')} - ${DateUtils.formatDate(
          end
        )}`;
      }
      return `${DateUtils.formatDate(start, 'dd MMM')} - ${DateUtils.formatDate(
        end
      )}`;
    }
    if (isSameMonth(start, end)) {
      return `${DateUtils.formatDate(start)} - ${DateUtils.formatDate(end)}`;
    }
    return `${DateUtils.formatDate(start)} - ${DateUtils.formatDate(end)}`;
  };

  const handleExperienceClickAction = () => {
    if (hasLink) {
      return;
    }
    if (onExploreClick) {
      onExploreClick(id);
    }
  };

  const handleShowExminderProfile = (e: MouseEvent) => {
    if (onShowExminderProfileClick) {
      e.stopPropagation();
      onShowExminderProfileClick(exminder?.id ?? '');
    }
  };

  const handleShowExplorerProfile = (e: MouseEvent) => {
    if (onShowExplorerProfileClick) {
      e.stopPropagation();
      onShowExplorerProfileClick(explorer?.id ?? '');
    }
  };

  const getLatestWorkExperience = (): LatestWorkExperience => {
    if (!exminder?.workExperience) {
      return {
        companyRole: '',
        companyName: '',
      };
    }
    const sortedWorkExperiences = exminder.workExperience.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );

    return {
      companyRole: sortedWorkExperiences[0]?.companyRole ?? '',
      companyName: sortedWorkExperiences[0]?.companyName ?? '',
    };
  };

  const getDomainIcon = (): ReactElement | null => {
    const iconMap: { [key in Domain]: ReactElement } = {
      business: <BusinessIcon />,
      lifestyle: <LifestyleIcon />,
      experts: <ExpertsIcon />,
      technology: <TechnologyIcon />,
      creativity: <CreativityIcon />,
      traveling: <TravelingIcon />,
      adventures: <AdventuresIcon />,
      parenting: <ParentingIcon />,
      healthcare: <HealthcareIcon />,
      mindfulness: <MindfulnessIcon />,
      small_talk: <SmallTalkIcon />,
      extras: <ExtrasIcon />,
    };

    return iconMap[domain as Domain] || null;
  };

  const getUserDetails = (user?: Exminder | Explorer) =>
    cardType === ExperienceCardType.Permanent && !user ? (
      <SC.UnboughtExperiencePlaceholder>
        <SC.BlackRegularBoldText>
          {t('experience.checkNotifications')}
        </SC.BlackRegularBoldText>
        <SC.ExplorerText>{t('experience.stayUpdated')}</SC.ExplorerText>
      </SC.UnboughtExperiencePlaceholder>
    ) : (
      <SC.UserDetails>
        <SC.UserDetailsContent>
          {user ? (
            <SC.UserAvatarContainer>
              <SC.UserAvatar
                src={user?.profilePicture || avatarPlaceholder}
                onError={(e: SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = avatarPlaceholder;
                }}
              />
            </SC.UserAvatarContainer>
          ) : null}
          <SC.UserNameWrapper>
            <SC.UserNameContent>
              {user ? (
                <>
                  <SC.BlackRegularBoldText>
                    {showExplorer
                      ? `${t('experience.explorer.myExperiences.purchasedBy')}`
                      : `${user.firstName} ${user.lastName}`}
                  </SC.BlackRegularBoldText>
                  {(showExplorer || !user) && (
                    <>
                      <SC.ExplorerText>
                        {`${user.firstName} ${user.lastName}`}
                      </SC.ExplorerText>
                      <SC.StyledButtonWrapper
                        onClick={handleShowExplorerProfile}
                      >
                        <SC.ShowProfileButton>
                          {t('experience.showProfile')}
                        </SC.ShowProfileButton>
                      </SC.StyledButtonWrapper>
                    </>
                  )}
                  {!showExplorer && (
                    <SC.ProfileDetails>
                      {getLatestWorkExperience().companyRole.length === 0 &&
                      getLatestWorkExperience().companyName.length === 0 ? (
                        <SC.StyledButtonWrapper
                          onClick={handleShowExminderProfile}
                        >
                          <SC.ShowProfileButton>
                            {t('experience.showProfile')}
                          </SC.ShowProfileButton>
                        </SC.StyledButtonWrapper>
                      ) : (
                        <>
                          <SC.CompanyRoleText>
                            {getLatestWorkExperience().companyRole}
                          </SC.CompanyRoleText>
                          <SC.CompanyNameText>
                            {getLatestWorkExperience().companyName}
                          </SC.CompanyNameText>
                        </>
                      )}
                    </SC.ProfileDetails>
                  )}
                </>
              ) : (
                <SC.UnboughtExperiencePlaceholder>
                  <SC.BlackRegularBoldText>
                    {t('experience.experienceHasNoBuyers')}
                  </SC.BlackRegularBoldText>
                  <SC.ExplorerText>
                    {t('experience.stayUpdated')}
                  </SC.ExplorerText>
                </SC.UnboughtExperiencePlaceholder>
              )}
            </SC.UserNameContent>
          </SC.UserNameWrapper>
        </SC.UserDetailsContent>
      </SC.UserDetails>
    );

  const renderExperienceTypeIcon = () => {
    switch (typeOfExperience) {
      case ExperienceType.HANDS_ON:
        return (
          <SC.ExperienceTypeIconContainer $path={SvgPath.STROKE}>
            <HandsOnIcon />
          </SC.ExperienceTypeIconContainer>
        );
      case ExperienceType.MENTORSHIP:
        return (
          <SC.ExperienceTypeIconContainer $path={SvgPath.FILL}>
            <MentorshipIcon />
          </SC.ExperienceTypeIconContainer>
        );
      case ExperienceType.SHADOWING:
        return (
          <SC.ExperienceTypeIconContainer $path={SvgPath.FILL}>
            <ShadowingIcon />
          </SC.ExperienceTypeIconContainer>
        );
      default:
        return null;
    }
  };

  return (
    <SC.Container
      $extendedCardHeight={hasActionButton}
      $showUser={showUser}
      onClick={handleExperienceClickAction}
    >
      {hasLink && <SC.LinkOverlay to={href} />}
      <SC.CardContent>
        <SC.ImageContainer>
          <ExperienceOverlay
            currentStatus={effectiveStatus as ExperienceStatus | RequestStatus}
          />
          <SC.Overlay />
          <SC.Image
            src={presentationPhoto}
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = mediaPlaceholder;
            }}
          />
          <SC.ImageDateContainer>
            <SC.ExperienceTitle>{title}</SC.ExperienceTitle>
            <SC.DateRow>
              <SC.DateContainer>
                <SC.IconContainer>
                  <CalendarIcon />
                </SC.IconContainer>
                <SC.DateText>
                  {cardType === ExperienceCardType.Permanent
                    ? t('experience.permanent.name')
                    : `${formattedDateRange()}${
                        DateUtils.isSameDay(startDate, endDate)
                          ? ` - ${DateUtils.extractTimeFromDate(startDate)}`
                          : ``
                      }`}
                </SC.DateText>
              </SC.DateContainer>
            </SC.DateRow>
          </SC.ImageDateContainer>
        </SC.ImageContainer>
        <SC.ExperienceDetailsContainer>
          {showExplorer && showUser ? getUserDetails(explorer) : null}
          {exminder && showUser ? getUserDetails(exminder) : null}
          {showUser ? <SC.Divider /> : null}
          <SC.ExperienceDetailsContent>
            <SC.Detail>
              <SC.ColoredIconContainer>
                <LocationPinIcon />
              </SC.ColoredIconContainer>
              <SC.DetailText>{location}</SC.DetailText>
            </SC.Detail>
            <SC.DetailRow>
              <SC.Detail>
                <SC.ColoredIconContainer>
                  {getDomainIcon()}
                </SC.ColoredIconContainer>
                <SC.DetailText>{formatDomainHobbyName(domain)}</SC.DetailText>
              </SC.Detail>
              <SC.Detail>
                {renderExperienceTypeIcon()}
                <SC.DetailText>
                  {renderExperienceTypeLabel(typeOfExperience)}
                </SC.DetailText>
              </SC.Detail>
            </SC.DetailRow>
            <SC.Detail>
              <SC.ExperiencePriceContainer>
                <SC.ColoredIconContainer>
                  <CashIcon />
                </SC.ColoredIconContainer>
                <SC.PricesContainer>
                  {discount && discount > 0 && finalPrice > 2 ? (
                    <SC.OriginalPriceContainer>
                      {formatNumberByLocale(Number(price))}
                      <SC.CurrencyContainer>{` ${currency}`}</SC.CurrencyContainer>
                    </SC.OriginalPriceContainer>
                  ) : null}
                  <SC.CurrentPriceContainer>
                    {formatNumberByLocale(finalPrice >= 2 ? finalPrice : price)}
                    <SC.CurrencyContainer>{` ${currency}`}</SC.CurrencyContainer>
                  </SC.CurrentPriceContainer>
                </SC.PricesContainer>
              </SC.ExperiencePriceContainer>
            </SC.Detail>
          </SC.ExperienceDetailsContent>
          {hasActionButton && (
            <SC.ButtonContainer>
              {actionButton === ActionButton.EXPLORE &&
              (onExploreClick || hasLink) ? (
                <SC.StyledButton
                  color={theme.palette.button.main.yellow}
                  href={hasLink ? href : undefined}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleExperienceClickAction();
                  }}
                >
                  {t('general.explore')}
                  <SC.ButtonIconContainer>
                    <ExploreIcon />
                  </SC.ButtonIconContainer>
                </SC.StyledButton>
              ) : null}
              {actionButton === ActionButton.SETTINGS && onSettingsClick ? (
                <SC.StyledButton
                  color={theme.palette.main.lightGray}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onSettingsClick();
                  }}
                >
                  {t('addExperience.btnSettings')}
                </SC.StyledButton>
              ) : null}
              {actionButton === ActionButton.REVIEW && onLeaveAReviewClick ? (
                <SC.StyledButton
                  color={theme.palette.button.main.yellow}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onLeaveAReviewClick(id);
                  }}
                >
                  {t('afterExperience.leaveAReview')}
                </SC.StyledButton>
              ) : null}
            </SC.ButtonContainer>
          )}
        </SC.ExperienceDetailsContainer>
      </SC.CardContent>
    </SC.Container>
  );
}
