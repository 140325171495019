export default {
  experienceCreationLayout: {
    leaveTheProcess: 'Are you sure you want to leave the page?',
    dataWillBeLost: 'Your current progress will be lost.',
    dateType: {
      chooseTheDateType: 'Choose the Experience date type',
      oneTime: 'One-Time',
      permanent: 'Permanent',
      oneTimeDescription:
        'The Experience is scheduled and takes place on a specific date',
      permanentDescription:
        'The Experience remains accessible until you manually disable it',
    },
    draft: {
      title: 'You have a saved draft',
      description:
        'Would you like to continue editing your draft or start a new Experience?',
      startNew: 'New Experience',
      continueWithDraft: 'Continue Draft',
    },
    step1: {
      welcomeMessageFirstPart: 'Hello',
      welcomeMessageSecondPart:
        '! We’re glad you decided to meet more Explorers and make money out of your skills.',
      newExperienceMessage: 'Let’s add a new Experience!',
      boxesMessage: 'There are going to be 11 boxes to fill in',
      nameMessage: 'What will the name of your Experience be?',
    },
    locationStep: {
      title: 'Meeting Point',
      message: 'Set a point where you and the Explorer will meet.',
    },
    step3: {
      title: 'Experience Language',
      message: 'What language will be spoken during your Experience?',
    },
    step4: {
      title: 'Experience Start Date',
      message: 'In what day will the Experience be starting?',
    },
    step5: {
      title: 'Experience End Date',
      message: 'In what day will the Experience be ending?',
    },
    step6: {
      title: 'Start Time',
      message: 'At what time will the Experience be starting?',
      checkbox: 'The Experience starts and ends in the same day.',
    },
    step7: {
      title: 'End Time',
      message: 'At what time will the Experience be ending?',
      error:
        ' If start date is the same as end date, end time should be later than start time',
    },
    step8: {
      title: 'Number of Explorers',
      message: 'How many people can buy your Experience?',
    },
    step9: {
      title: 'Presentation Video',
      message: 'Upload your presentation video',
      errorMessage: ' Your browser does not support the video tag.',
      videoUpload:
        'Uploading might take a few moments depending on the lenght of the presentation video and the size of the presentation photo.',
      closeApp:
        'Please keep the Exminds app open while the Experience is being created.',
    },
    step10: {
      title: 'Experience Description',
      message:
        'Tell us more about your Experience. Be as specific as possible, because Explorers will read this to discover if it suits them or not. This can trigger and lead to your best connections.',
    },
    step11: {
      title: 'Exminder Disclaimer',
      message:
        'What are your Experience requirements? Here you can write all the details you want the Explorer to be aware of.',
    },
    step12: {
      title: 'Price',
      message: 'How much will the Experience cost?',
    },
    step13: {
      title: 'Currency',
      message: 'What’s the currency you’ll use?',
    },
    step14: {
      title: 'Additional costs',
      message: 'Are there any additional costs?',
      costs: 'What are the additional costs?\nWrite more details below.',
      noAdditionalCosts: 'No additional costs',
    },
    step15: {
      title: 'Discounts',
      message: 'Do you want to add any discounts?',
      discounts:
        'Write here the percentage and we will calculate the new price for you.\n' +
        '\n' +
        'No worries, you can deactivate it later.',
      actualPrice: 'Actual price',
      newPrice: 'New price',
      discountPercentage: 'Discount percentage',
    },
    step16: {
      title: 'Purchase Deadline',
      message: 'What is the deadline for the Explorers to buy your Experience?',
    },
    step17: {
      title: 'Contact',
      message: 'Can they contact you via messages or mobile?',
    },
    step18: {
      title: 'Choose Experience type',
      message: 'Pick the option that best describes you Experience',
      handsOn: 'Hands-On Experience',
      mentorship: 'Mentorship Experience',
      shadowing: 'Shadowing Experience',
      handsOnDescription:
        'The Explorer is actively involved in the Exminder’s activities',
      mentorshipDescription:
        'The Explorer takes part in a structured conversation with the Exminder',
      shadowingDescription:
        'The Explorer is observing the Exminder’s activities',
    },
    step19: {
      title: 'Experience Domain',
      message: 'What domain best describes your Experience?',
    },
    step20: {
      title: 'Presentation Image',
      message: 'Upload your presentation image',
    },
  },
};
