export default {
  aboutTheApp: {
    aboutTheAppIntro: {
      title: 'Welcome to the new dimension of spending time!',
      subtitle:
        'Connect with people of your interest and evolve by sharing incredible Experiences with them.',
    },
    aboutTheAppFirst: {
      title: 'A Knowledge-Sharing platform meant to help you value your time!',
      subtitle:
        'Turn your life experience into lessons for others, or gain valuable knowledge from those you admire.' +
        '\n\n' +
        'Connect online. Experience offline.',
    },
    aboutTheAppSecond: {
      title: 'Be an Exminder!',
      subtitle:
        'Share your knowledge and transform lives.' +
        '\n\n' +
        'Create Experiences that turn your skills into valuable opportunities for others.',
    },
    aboutTheAppThird: {
      title: 'Be an Explorer!',
      subtitle:
        "Meet the people you've always admired, live transformative experiences, and shape your own reality.",
    },
    aboutTheAppFourth: {
      title: 'Upgrade anytime!',
      subtitle:
        "From an apprentice Explorer to a master Exminder, upgrade anytime you're ready to share your knowledge with the world." +
        '\n\n' +
        'Exminds is all about evolution.',
    },
    aboutTheAppFifth: {
      title: 'How it works?',
      subtitle:
        'The Exminder creates the Experience, the Explorer chooses it, and... It’s time for a 1:1 in-person transformative Experience!',
    },
    aboutTheAppSixth: {
      title: 'The rest is...Reality!',
      subtitle:
        'Bring practice into your daily life and enjoy your journey in the Exminds Universe!',
    },
  },
};
