export default {
  errorBoundary: {
    title: 'Oh no! Something went wrong!',
    subtitle:
      'We are currently experiencing a hiccup in our process, but not to worry! While we work on fixing it, we suggest exploring the other pages for a seamless browsing experience.',
    recoveryButton: 'Homepage',
  },
  experienceNotFound: {
    title: 'This Experience was not found!',
    subtitle:
      'It looks like the Experience you are looking for is not available right now.\n\nThis might be because it is outdated or it has been removed from the Exminder’s profile.',
  },
  profileNotFound: {
    title: 'This user was not found!',
    subtitle:
      'We could not find the user you are looking for.\n\nIt might be because they have changed their profile or no longer exist.',
  },
  networkError: {
    title: 'No internet connection!',
    subtitle: 'Please check your internet connection and try again.',
    action: 'Retry',
  },
};
