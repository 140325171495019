export default {
  formValidations: {
    correctEmailOrPassword: 'Corectează următoarele erori: email sau parolă',
    correctEmailAndPassword: 'Corectează următoarele erori: email și parolă',
    correctEmail: 'Corectează următoarele erori: email',
    correctPassword: 'Corectează următoarele erori: parolă',
    pleaseFillOutThisField: 'Te rog să completezi acest câmp',
    invalidEmailAddress: 'Adresă de email invalidă',
    duplicateEmail: 'Email-ul este deja utilizat',
    passwordMismatch: 'Parolele nu se potrivesc',
  },
};
