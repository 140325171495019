export default {
  faq: {
    1: {
      question: 'How does Exminds work?',
      answer:
        'Exminds creates a digital universe that connects people online for offline Experiences. ' +
        'Here time becomes the most important form of human exchange. Firstly, pick your fighter:' +
        '\n\n',
      answerExminderBold: '- I want to be an Exminder!\n',
      answerExminderItalic:
        'Do you feel like Yoda from Star Wars? Then teach the path of the Jedi and make money. Yes, hrrrm.\n\n',
      answerExplorerBold: '- I want to be an Explorer!\n',
      answerExplorerItalic:
        'Yes, just like Dora the Explorer, you travel the world in search of answers, ' +
        'even if it means you have to break your piggy bank.\n\n',
      answerEndOne: 'Secondly, the ',
      exmindsCircuit: 'Exminds circuit',
      answerEndTwo:
        ' works like this: the Exminder creates the Experience package, ' +
        'the Explorer buys it aaaand… magic happens. The Experience takes place in the real world, ' +
        'under the agreed conditions, then the final feedback is given and the adventure continues.\n\n' +
        'The best part? Sooner or later, all the Explorers evolve and discover what they can offer the world, ' +
        'further becoming Exminders.',
    },
    2: {
      question: 'What is the difference between an Exminder and an Explorer?',
      answer:
        'An Exminder is a person who, through its life and professional experience, can offer value to others. ' +
        'Exminders are able to create Experience packages that can be bought by the Explorers, ' +
        'hence this requires owning a company or being an active taxpayer in your residence country.' +
        '\n\n' +
        'An Explorer is the person who evolves by living new Experiences, along the people they dreamed of meeting. ' +
        'They can access unlimited opportunities by buying the Experience packages available on our platform ' +
        'and need no form of legal entity to do so.' +
        '\n\n' +
        'If you think you’re up for the challenge, you can Experience both of these roles - access global packages ' +
        'or create them yourself. The choice is yours.',
    },
    3: {
      question: "What's the first step after creating my account?",
      answer:
        'If someone with a hidden face sold you lemonade, would you buy it? Of course we love to get to know our people!' +
        '\n\n' +
        'You have just discovered the Exminds Universe and now it’s time to let the world know the real you. ' +
        'The next step is to complete your entire profile and win the trust of the community.' +
        '\n\n' +
        'Then, your adventure starts and the Experiences are limitless!',
    },
    4: {
      question: 'Do I need any certifications to use Exminds?',
      answer:
        "You don't have to be a wizard to use Exminds, it’s all about having the time of your life." +
        '\n\n' +
        'As an Exminder, if the Experience requires having specific certifications or specialized studies, ' +
        'it is your responsibility to demonstrate your background. ' +
        'Exminds does not undertake verifications in this regard.' +
        '\n\n' +
        'As an Explorer, dive into the unimaginably vast world of human experience, ' +
        'where you can learn so much from everyone you meet. Of course, ' +
        'you only need the desire for evolution to enjoy this!',
    },
    5: {
      question: 'What does an Experience Package include?',
      answer:
        'Each Experience package is created, organized and personalized by the Exminder. ' +
        'Everything will be described in detail, so you can understand what your benefits are as a participating Explorer.' +
        '\n\n' +
        'The service and its price is fully provided by the Exminder, hence expect for the best, prepare for authenticity. ' +
        'Surprises are part of the process and living the Experience will always include paying for it.',
    },
    6: {
      question: 'How does a One-Time Package work?',
      answer:
        'The One-Time Package is designed for Exminders with tight schedules or special events. The Exminder must provide extensive details about the date, location, and duration of the Experience for the Explorer. Before making a purchase, the Explorer should thoroughly review all Experience details to minimize refund scenarios.',
    },
    7: {
      question: 'How does a Permanent Package work?',
      answer:
        'The Permanent Package remains on your profile until you deactivate it. This allows Explorers to express their interest in your Experiences by clicking "I\'m interested". Depending on your availability, you can then schedule them accordingly. You have the option to accept or decline their request. If accepted, they can proceed with the purchase, initiating a 20-day countdown.',
    },
    8: {
      question:
        'What is the difference between a Shadowing, a Mentorship and a Hands-On Experience?',
      answer:'The Shadowing Experience involves the Explorer observing the Exminder in action, learning through observation, and asking strategic questions to gain insights. It focuses on learning by example and understanding the context of the work environment.' +
        '\n\n' +
        'The Mentorship Experience is more interactive, centered around guided conversations where the Exminder provides tailored advice based on the Explorer\'s specific needs. It\'s a problem-solving approach, focusing on growth and development in a particular scenario.' +
        '\n\n' +
        'The Hands-On Experience allows the Explorer to actively participate in tasks alongside the Exminder, gaining practical, real-world experience by taking part in activities, discussions, and decision-making processes. It fosters learning by doing and being directly involved.',
    },
    9: {
      question:
        'Do I need to own a legal form such as a company, a self-employed status etc.?',
      answer:
        'The Exminder needs a legal form of recording money and paying taxes, hence yes, ' +
        'you need to have a functional company, either you assume your own responsibility.' +
        '\n\n' +
        'The Explorer does not need a company, but if you want a specific invoice, ' +
        'we need to check beforehand what it implies, so better contact us and we will take care of it.',
    },
    10: {
      question: 'How can I publish my Experience?',
      answer:
        'After finishing the Experience creation process, make sure you\'ve completed these steps: Confirm your phone, register financial details with Stripe, and set up Benefits and Requirements for each package in "My Experiences" section. Then, activate your Experience and it’ll be sent to our team\'s quality check to ensure it aligns with Exminds Community Guidelines before it goes live. If this doesn’t happen you’ll be notified on the app and email.',
    },
    11: {
      question: 'What are the costs of using the app?',
      answer:
        'All the Explorers are happy and free of charge to explore and buy all the wished Experiences.' +
        '\n\n' +
        'As for Exminders, you can list any packages you want, of any kind. ' +
        'You can be sure that there are no anticipated fees, only when your package is purchased, ' +
        'you pay the commission fee to the Exminds company.',
    },
    12: {
      question: 'How can I invite a friend for free?',
      answer:
        'It’s easy-peasy-Exminds-squeezy: buy your favourite Experience package and introduce your friends’ email address in the field that will appear on your screen right after the purchase has been made. Your friend will then receive an invitation link on the provided email address and can create an account to find out more information on the Exminder and the Experience he/she will be attending alongside you.',
    },
    13: {
      question:
        'What’s the period the Explorer and Exminder can meet for a Permanent Package?',
      answer:
        'After the payment is done you have 20 days to meet each other. The Exminder has the task to request the PIN from the Explorer in order to prove the meeting took place in real life.',
    },
    14: {
      question:
        'As an Exminder when do I receive the money for a One-Time Package?',
      answer:
        'Three (3) days after an Experience ends IF no one has reported a problem, the Exminder receives the money. ' +
        'If unexpected situations arise, such as someone not showing up, ' +
        'the terms of the agreement not being respected, ' +
        'the Exminds Core Team will analyze each case separately and resolve it as soon as possible.' +
        '\n\n' +
        'You can report such situations at any time by email at ',
      experiencesMail: 'experiences@exminds.com',
      answerEnd: '. Worry not, you are in good hands.',
    },
    15: {
      question:
        'When do I receive the money after a Permanent Package has been finished?',
      answer:
        'For Permanent Packages, the PIN must be introduced within 15 days, meaning you have 15 maximum days to meet the Explorer, from the day your Experience was purchased.' +
        '\n\n' +
        'Otherwise, the Explorer has a 3-day window to request a refund, after the 15 days gap. If no refund is initiated within this timeframe, the Experience is considered completed through implied mutual agreement.',
    },
    16: {
      question:
        'When do Exminders receive the money transfer from Exminds to their bank account?',
      answer:
        'On average, funds are received within 7 days from the date the PIN is introduced, provided there are no disputes.',
    },
    17: {
      question:
        'Do I need to send invoices to the Explorers or Exminds does it?',
      answer:
        "For billing purposes, if you have a Company Account, you'll have the option during account creation to choose whether you want Exminds to send invoices on your behalf. This option can also be set later in the Financial Details section.",
    },
    18: {
      question: 'What’s coming next?',
      answer:
        'Do you want some spoilers about the expansion of the Exminds Universe?' +
        '\n\n' +
        'Our important mission and global vision unite to reshape the future of human interaction. ' +
        'New businesses and partnerships incoming, ' +
        'working on building up new opportunities for communities.' +
        '\n\n' +
        'We are working on the world that will change your world. Trust the process and stay on the Exminds side!',
    },
    19: {
      question: 'Got a question not answered here?',
      answerFirst:
        'Problem-solving is our middle name. If your unicorn has lost its horn, ' +
        'or a button in the application no longer works, reach us at ',
      supportMail: 'support@exminds.com',
      fullstop: '.',
      answerSecond:
        '\n\nThe team works day and night to ensure that Exminds is an extraordinary journey. Thank you for being on board!',
    },
  },
};
