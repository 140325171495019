export default {
  auth: {
    signUp: 'Înregistrează-te',
    signIn: 'Conectează-te',
    logIn: 'Autentificare',
    logOut: 'Deconectare',
    continueWithGoogle: 'Continuă cu Google',
    continueWithApple: 'Continuă cu Apple',
    verifyEmail: 'Verifică emailul',
    emailVerified: 'Email-ul tău a fost verificat',
    emailVerifiedDescription:
      'Acum că emailul tău a fost verificat, putem continua procesul de creare a contului pentru a avea acces la Universul Exminds!',
    checkSpamFolder:
      'Am trimis un email către adresa ta. Te rugăm să verifici și folderul de Spam pentru a ne asigura că primești mesajul.',
    resendEmail: 'Retrimite',
    returnToProcess: 'Întoarce-te',
    createAccount: 'Creează Cont',
    enterCredentials: 'Introdu credențialele pentru a continua',
    enterEmailAndPassword: 'Introdu emailul și parola',
    email: 'Email',
    password: 'Parola',
    reEnterPassword: 'Reintrodu parola',
    byContinuing: 'Sunt de acord cu',
    termsOfService: 'Termenii Serviciului',
    and: 'și',
    privacyPolicy: 'Politica de Confidențialitate',
    alreadyHaveAccount: 'Ai deja un cont?',
    dontHaveAccount: 'Nu ai un cont?',
    forgotPassword: 'Ți-ai uitat parola?',
    forgotPasswordTitle: 'Ți-ai uitat Parola',
    resetPassword: 'Resetează Parola',
    enterYourEmail: 'Introdu adresa de email asociată contului tău',
    enterAndConfirmNewPassword: 'Introdu și confirmă o nouă parolă',
    newPassword: 'Parola Nouă',
    repeatPassword: 'Repetă Parola',
    passwordIsFresh: 'Parola ta a fost schimbată',
    returnToLogIn: 'Acum te poți întoarce la procesul de autentificare!',
    leavingTheExmindsWorld: 'Părăsești universul Exminds?',
    areYouSureYouWantToLogOut: 'Ești sigur că vrei să te deconectezi?',
    leaveTheProcess: 'Ești sigur că vrei să părăsești pagina?',
    dataWillBeLost: 'Orice date vor fi pierdute',
    openApp: {
      title: 'Deschiderea aplicației necesară',
      description:
        'Pentru a continua, vă rugăm să deschideți mai întâi aplicația. Acest pas este necesar pentru a asigura cea mai bună experiență și funcționalitate completă.',
      action: 'Confirmă',
    },
  },
};
