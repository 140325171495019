export default {
  experience: {
    type: {
      handsOn: 'Hands-On',
      mentorship: 'Mentorship',
      shadowing: 'Shadowing',
    },
    status: {
      draft: 'Draft',
      completed: 'Completed',
      expired: 'Expired',
      canceled: 'Canceled',
      shadowBan: 'Waiting for approval',
    },
    title: 'Description',
    editForm: {
      reupload: 'Reupload',
      name: 'Name*',
      startDate: 'Start date*',
      endDate: 'End date*',
      startTime: 'Start time*',
      endTime: 'End time*',
      experienceType: 'Experience type*',
      domain: 'Domain*',
      participantsNumber: 'Participants number (maximum)*',
      deadlineDate: 'Deadline date*',
      deadlineTime: 'Deadline time*',
      presentationVideo: 'Presentation video*',
      presentationPhoto: 'Presentation photo*',
      location: 'Location*',
      benefits: 'Benefits*',
      requirements: 'Requirements*',
      exminderDisclaimer: 'Exminder disclaimer*',
      additionalCosts: 'Additional costs',
      currency: 'Currency*',
      price: 'Price*',
      discountsPercentage: 'Discounts percentage',
      referralCode: 'Referral code',
      contact: 'Contact',
      description: 'Experience description',
    },
    editDisclaimer: {
      title: 'Edit Exminder disclaimer',
      subtitle:
        'Provide all the important information for your Explorer to understand every aspect of your Experience',
      text: 'Disclaimer Text',
    },
    editDescription: {
      title: 'Edit Experience description',
      subtitle:
        'Provide all the important information for your Explorer to understand every aspect of this particular Experience',
      text: 'Description Text',
    },
    editCosts: {
      title: 'Edit additional costs',
      subtitle:
        'Provide all the important information regarding the potential additional costs for your Explorer to understand every aspect of your Experience',
      text: 'Additional costs',
    },
    ongoing: {
      mentionSomething: 'Mention something',
      takingPlace: 'This Experience is taking place.\nEnjoy!',
    },
    errors: {
      purchaseDeadlineDate: 'Purchase deadline date must be before start date',
    },
    filters: {
      accounts: 'Accounts',
      experiences: 'Experiences',
      auctions: 'Auctions',
    },
    explorer: {
      myExperiences: {
        welcome: 'Welcome, ',
        youHave: 'You have',
        experiencePurchased: 'purchased Experience',
        experiencesPurchased: 'purchased Experiences',
        experienceCompleted: 'completed Experience',
        experiencesCompleted: 'completed Experiences',
        experienceRequested: 'requested Experience',
        experiencesRequested: 'requested Experiences',
        purchasedBy: 'Purchased by',
        unpurchasedExperience: 'Unpurchased Experience',
        noActiveExplorersBought: 'No active Explorers bought this package',
        thisIsThePlace:
          'This is the place where you can store all your upcoming Experiences!',
        lookingForFreshExperiences:
          'Looking for fresh Experiences to learn from?',
        checkIfTheExminders:
          'Check if the Exminders have accepted your requests for the desired Experiences. Once accepted, you will need to proceed and buy the Experience.',
        upcoming: 'Upcoming',
        auction: 'Auction',
        past: 'Past',
        requested: 'Requests',
        discoverExperiences: 'Discover Experiences',
        noPurchasedExperiences:
          'It looks like you don’t have any purchased Experiences',
        noRequestedExperiences:
          'It looks like you don’t have any requested Experiences',
        noCompletedExperiences:
          'It looks like you don’t have any completed Experiences',
        findYourFavoriteOnes: 'Find more Experiences in the Exminds Universe!',
        exploreMore: 'Explore More',
      },
    },
    permanent: {
      name: 'Permanent',
      requestApproved: 'Request approved!',
      youHaveLeftToBuy: 'You have 3 days to buy this Experience',
      exminderIsOff: 'The Exminder is off!',
      isNotAvailable:
        'is not available at this moment for the Experience you are interested in',
    },
    refund: {
      request: 'Request Refund',
    },
    request: {
      approved: 'Approved',
      declined: 'Declined',
    },
    inProgress: 'In Progress',
    upcoming: 'Upcoming',
    requested: 'Requested',
    pinCodeValidatedAt: 'PIN code validated at',
    showProfile: 'Show Profile',
    checkNotifications: 'Check your notifications!',
    stayUpdated:
      'Stay updated on all the discussions with Explorers interested in this Experience',
    experienceHasNoBuyers: 'This Experience has no buyers',
    promoteExperience:
      'Promote your Experience for helping Explorers discover your skills',
    typeCard: {
      greeting: 'Hey',
      title: {
        handsOn: 'This is a Hands-On Experience',
        mentorship: 'This is a Mentorship Experience',
        shadowing: 'This is a Shadowing Experience',
      },
      description: {
        handsOn:
          'You can actively participate in tasks alongside the Exminder, gaining practical skills through direct involvement and real-world application.',
        mentorship:
          'You can have personalized guidance and advice, focused on helping the Explorer navigate specific challenges and achieve growth through expert insights.',
        shadowing:
          'You can observe and learn from the Exminder in action, gaining valuable insights through real-time analysing.',
      },
    },
    progressTracker: {
      title: 'How it works',
      subtitle:
        'Curious about how the Exminds purchase flow works? Here’s a breakdown of the steps involved:',
      step: 'STEP',
      interested: {
        name: "I'm interested",
        description:
          "An Explorer just discovered an incredible Experience and is excited to participate in it. If it's a Permanent Experience, you'll need to check Exminder's availability and express your interest first.\n\n",
        descriptionBold:
          "But be careful — this doesn't mean you've secured the Experience yet!",
      },
      checkAvailabilityAndBuy: {
        name: 'Check availability & Buy',
        description:
          'A face-to-face meeting requires the participation of both individuals.\n\n' +
          "Please understand that the Exminder needs to review the Explorer's Profile and professional background to provide their consent before meeting in person.\n\n",
        descriptionBold:
          'The Exminder has equal rights to choose whom they will to share their Experience with!',
      },
      getInContact: {
        name: 'Get in contact',
        descriptionOne: 'It’s time to get in touch with each other.\n\n',
        descriptionTwo:
          'This is especially important for Permanent Experiences, ' +
          'which don’t have a set date and time, ' +
          'making this the ideal moment to discuss and arrange these details.\n\n',
        descriptionBoldOne:
          'If the Explorer has any additional questions, they can easily reach out to the Exminder. ',
        descriptionBoldTwo:
          'You have 15 days after the purchase to arrange the meeting.',
      },
      meetInPersonAndRequestPin: {
        name: 'Meet in person & Validate PIN',
        description:
          'This step officially initiates the Experience, while also approves the money transfer to the Exminder. ' +
          "If this step is skipped, the payment won't be processed.\n\n" +
          'We strongly advise against sharing the PIN before meeting face-to-face.',
        descriptionBold:
          'For extra security, each Experience has a unique PIN that must be exchanged when the Exminder and Explorer meet in person.\n\n',
      },
      startExperience: {
        name: 'Start Experience',
        description:
          'The real journey begins now. Embrace every second and welcome the reality in.',
      },
      permanentExperience: {
        stepOne: "I'm\ninterested",
        stepTwo: 'Check availability\n& Buy',
        stepThree: 'Get\nin contact',
        stepFour: 'Meet in person\n& Validate PIN',
        stepFive: 'Start\nExperience',
      },
      oneTimeExperience: {
        stepOne: 'Buy\nExperience',
        stepTwo: 'Get\nin contact',
        stepThree: 'Meet in person\n& Validate PIN',
        stepFour: 'Start\nExperience',
      },
    },
  },
};
