import { ButtonHTMLAttributes } from 'react';
import * as SC from './styles';
import {
  ExperienceType,
  SvgPath,
} from '../../../../../../helpers/constants/enums';
import HandsOnIcon from '../../../../../../assets/icons/experience/details/HandsOnIcon';
import MentorshipIcon from '../../../../../../assets/icons/experience/details/MentorshipIcon';
import ShadowingIcon from '../../../../../../assets/icons/experience/details/ShadowingIcon';
import { renderExperienceTypeLabel } from '../../../../../../helpers/functions/experienceTypes';

interface ExperienceTypeButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  experienceType: ExperienceType;
  isActive: boolean;
  onClick: CallbackFunction;
}

export default function ExperienceTypeButton({
  experienceType,
  isActive,
  onClick,
}: ExperienceTypeButtonProps) {
  const renderExperienceTypeIcon = () => {
    switch (experienceType) {
      case ExperienceType.HANDS_ON:
        return (
          <SC.IconContainer $isActive={isActive} $path={SvgPath.STROKE}>
            <HandsOnIcon />
          </SC.IconContainer>
        );
      case ExperienceType.MENTORSHIP:
        return (
          <SC.IconContainer $isActive={isActive} $path={SvgPath.FILL}>
            <MentorshipIcon />
          </SC.IconContainer>
        );
      case ExperienceType.SHADOWING:
        return (
          <SC.IconContainer $isActive={isActive} $path={SvgPath.FILL}>
            <ShadowingIcon />
          </SC.IconContainer>
        );
      default:
        return null;
    }
  };

  return (
    <SC.Container onClick={onClick}>
      <SC.ReferenceContainer $isActive={isActive}>
        <SC.ReferenceInsideBorder>
          {renderExperienceTypeIcon()}
          <SC.Text $isActive={isActive}>
            {renderExperienceTypeLabel(experienceType)}
          </SC.Text>
        </SC.ReferenceInsideBorder>
      </SC.ReferenceContainer>
    </SC.Container>
  );
}
